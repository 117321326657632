import React, { Component } from 'react';
import './styles.css';
import { Alert, AlertTitle, Button, IconButton, Stack } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import imgLogin from '../../assets/imgLogin.jpg';
import Register from '../Register';
import { onValue, ref } from 'firebase/database';
import StartFirebaseUser from '../../configUserFirebase/index';

const db = StartFirebaseUser();

class Login extends Component {
  state = {
    username: '',
    password: '',
    showpass: false,
    errorLogin: false,
    successLogin: false,
    register: false,
    dataTable: []
  }

  componentDidMount() {
    const dbRef = ref(db, 'user');
    onValue(dbRef, (snapshot) => {
      let records = [];
      snapshot.forEach(childSnapshot => {
        let data = childSnapshot.val();
        records.push(data);
      });
      this.setState({ dataTable: records })
    });
  }

  _handleChangeLogin = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  _handleLogin = () => {
    const { username, password, dataTable } = this.state;
    const checkUser = dataTable?.filter(val =>
      val?.username === username &&
      val?.password === password &&
    val?.unit === 'rekam medis')?.length === 1;
    if (checkUser) {
      localStorage.setItem('login', true);
      localStorage.setItem('username', username);
      localStorage.setItem('unit', 'rekamMedis');
      this.setState({ successLogin: true }, () => {
        setTimeout(() => window.location.reload(), 3000)
      });
    } else {
      this.setState({ errorLogin: true }, () => {
        setTimeout(() => this.setState({ errorLogin: false }), 3000)
      });
    }
  }

  _handleShowPass = () => {
    this.setState({ showpass: !this.state.showpass });
  }

  _handleRegister = () => {
    this.setState({ register: !this.state.register });
  }

  _handleBack = (val) => {
    this.setState({ register: val })
  }

  _renderAlertSuccess = () => {
    return (
      <Stack sx={{ position: 'absolute', top: 10 }} spacing={2}>
        <Alert severity="success">
          <AlertTitle>Login Berhasil!</AlertTitle>
          Anda akan dialihkan ke halaman Pendaftaran Pasien Rawat Jalan
        </Alert>
      </Stack>
    );
  }

  _renderAlertError = () => {
    return (
      <Stack sx={{ position: 'absolute', top: 10 }} spacing={2}>
        <Alert severity="error">
          <AlertTitle>Login Gagal!</AlertTitle>
          Cek kembali Username dan Password yang Anda masukkan.
        </Alert>
      </Stack>
    );
  }

  render() {
    const { username, password, errorLogin, successLogin, showpass, register } = this.state;
    if (register) {
      return <Register onBack={this._handleBack} />
    } else {
      return (
        <div className='container'>
          {successLogin && this._renderAlertSuccess()}
          {errorLogin && this._renderAlertError()}
          <div className='wrapp-form'>
            <h2>SELAMAT DATANG</h2>
            <p>Silahkan Login terlebih dahulu untuk masuk ke Pendaftaran Pasien Rawat Jalan</p>
            <div className='wrapp-fields'>
              <p>Username</p>
              <input
                style={{ marginLeft: 3, width: '375px', marginBottom: 10, padding: 10, borderRadius: '4px' }}
                name='username'
                onChange={this._handleChangeLogin}
              />
              <p>Password</p>
              <div style={{ position: 'relative' }}>
                <input
                  style={{ marginLeft: 3, width: '375px', padding: 10, borderRadius: '4px' }}
                  type={showpass ? 'text' : 'password'}
                  name='password'
                  onChange={this._handleChangeLogin}
                />
                {showpass ?
                  <IconButton
                    onClick={this._handleShowPass}
                    style={{ position: 'absolute', right: 5, top: -4, zIndex: 2 }}
                  >
                    <VisibilityOff style={{ fontSize: '30px', color: 'grey' }} />
                  </IconButton> :
                  <IconButton onClick={this._handleShowPass} style={{ position: 'absolute', right: 5, top: -4, zIndex: 2 }}>
                    <Visibility style={{ fontSize: '30px', color: 'grey' }} />
                  </IconButton>
                }
              </div>
              <div style={{ display: 'flex', fontSize: '14px', justifyContent: 'end', marginTop: '10px' }}>
                <div>Belum punya akun?</div>
                <div className="daftar" onClick={this._handleRegister}>Daftar disini</div>
              </div>
              <Button
                disabled={
                  username === '' ||
                    password === '' ? true : false
                }
                fullWidth
                variant='contained'
                onClick={this._handleLogin}
                style={{ marginTop: 20 }}
              >
                Login
              </Button>
            </div>
          </div>
          <div>
            <img alt='' src={imgLogin} style={{ height: '100vh' }} />
          </div>
        </div>
      );
    }
  }
}

export default Login;