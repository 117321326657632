import { ArrowBack, CheckCircle, HighlightOff } from '@mui/icons-material';
import { Box, Button, IconButton, LinearProgress, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './styles.css';
import imgLogin from '../../assets/imgLogin.jpg';
import { ref, onValue, set } from 'firebase/database';
import StartFirebaseUser from '../../configUserFirebase/index';

const db = StartFirebaseUser();

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

export default function Register(props) {
  const { onBack } = props;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [nip, setNip] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorRegister, setErrorRegister] = useState(false);
  const [modalSubmit, setModalSubmit] = useState(false);
  const [dataTable, setDataTable] = useState([]);

  useEffect(() => {
    const dbRef = ref(db, 'user');
    onValue(dbRef, (snapshot) => {
      let records = [];
      snapshot.forEach(childSnapshot => {
        let data = childSnapshot.val();
        records.push(data);
      });
      setDataTable(records);
    })
  }, []);

  const handleBack = () => {
    onBack(false);
  }

  const _handleGoToList = () => {
    if (errorRegister) {
     setErrorRegister(false);
     setModalSubmit(false);
    } else {
      onBack(false);
    }
  }

  const _handleChangeNIP = (e) => {
    setNip(e.target.value);
  }

  const _handleChangeUsername = (e) => {
    setUsername(e.target.value);
  }

  const _handleChangePassword = (e) => {
    setPassword(e.target.value);
  }

  const _handleRegistrasi = () => {
    setLoading(true);
    setModalSubmit(true);
    const checkUser = dataTable.filter(val => val?.username === username)?.length === 1;
    if (checkUser) {
      setTimeout(() => {
      setLoading(false);
      setErrorRegister(true);
    }, 3500);
    } else {
      setTimeout(() => {
        const data = {
          queueNumber: dataTable?.length + 1,
          nip: nip, username: username, password: password,
          unit: 'rekam medis'
        }
        setLoading(false);
        set(ref(db, 'user/' + data?.queueNumber), { ...data });
      }, 3500);
    }
  }

  const _renderSuccessRegist = () => {
    return (
      <Modal open={modalSubmit}>
        <Box sx={styleModal}>
          {loading ?
            <Box sx={{ width: '100%' }}>
              <h3>Menyimpan Data...</h3>
              <LinearProgress />
            </Box> :
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              {
                errorRegister ?
                <>
                  <HighlightOff color='error' sx={{ fontSize: 150 }} />
                  <h3>Username sudah terdaftar!</h3>
                </> :
                <>
                  <CheckCircle color='success' sx={{ fontSize: 150 }} />
                  <h3>Pendaftaran Akun Berhasil!</h3>
                </> 
              }
              <Button
                variant="contained"
                color="success"
                onClick={_handleGoToList}
              >
                {errorRegister ? 'Coba Lagi' : 'Login'}
              </Button>
            </div>
          }
        </Box>
      </Modal>
    )
  }

  return (
    <div className='container'>
      {_renderSuccessRegist()}
      <div className='wrapp-form'>
        <IconButton onClick={handleBack}><ArrowBack /></IconButton> <b>Kembali</b>
        <h2>REGISTRASI AKUN</h2>
        <p>Silahkan isi data dengan baik dan benar!</p>
        <div className='wrapp-fields'>
          <p>NIP</p>
          <input
            style={{ marginLeft: 3, width: '375px', marginBottom: 10, padding: 10, borderRadius: '4px' }}
            name='nip'
            value={nip}
            onChange={_handleChangeNIP}
          />
          <p>Username</p>
          <input
            style={{ marginLeft: 3, width: '375px', marginBottom: 10, padding: 10, borderRadius: '4px' }}
            name='username'
            value={username}
            onChange={_handleChangeUsername}
          />
          <p>Password</p>
          <input
            style={{ marginLeft: 3, width: '375px', marginBottom: 10, padding: 10, borderRadius: '4px' }}
            name='password'
            value={password}
            onChange={_handleChangePassword}
          />
          <Button
            disabled={
              username === '' ||
                nip === '' ||
                password === '' ? true : false
            }
            fullWidth
            variant='contained'
            onClick={_handleRegistrasi}
            style={{ marginTop: 20 }}
          >
            Daftar
          </Button>
        </div>
      </div>
      <div>
        <img alt='' src={imgLogin} style={{ height: '100vh' }} />
      </div>
    </div>
  )
};