import { Component } from "react";
import { ref, onValue, set } from 'firebase/database';
import StartFirebase from '../../configFirebase/index';
import { Box, Button, CircularProgress, FormControl, IconButton, InputAdornment, Modal, OutlinedInput, Pagination, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import moment from "moment";
import { CheckCircle, Print, Search } from "@mui/icons-material";
import { Document, PDFViewer, Page, View } from "@react-pdf/renderer";
import CombineForm from "./CombineForm";
import LabForm from "./LabForm";
import RadiologiForm from "./RadiologiForm";
import FarmasiForm from "./FarmasiForm";
import KassaForm from "./KassaForm";

const db = StartFirebase();

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const styleModalForm = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  height: '80%',
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};

const viewer = {
  width: '100%',
  height: '550px',
};

const page = {
  backgroundColor: "white",
  color: "black",
  fontSize: 12
};

const section = {
  margin: 10,
  padding: 10,
};

const pageSize = 10;

class CombineFile extends Component {
  state = {
    preliminaryData: [],
    database: [],
    dataTable: [],
    search: '',
    pagination: {
      count: 0,
      from: 0,
      to: pageSize
    },
    dataPasien: [],
    showModal: false,
    loading: false,
    index: null,
  }

  componentDidMount() {
    const dbRef = ref(db, 'pasien');
    this.setState({ database: StartFirebase() });
    onValue(dbRef, (snapshot) => {
      let records = [];
      snapshot.forEach(childSnapshot => {
        let data = childSnapshot.val();
        records.push(data);
      });
      this.setState({
        dataTable: records?.filter(val => val?.isBillKassa === true),
        preliminaryData: records?.filter(val => val?.isBillKassa === true),
        pagination: {
          ...this.state.pagination,
          count: records?.filter(val => val?.isBillKassa === true)?.length
        }
      });
    });
  }

  _handleCloseModal = () => {
    this.setState({ showModal: false });
  }

  _handlePrint = (noReg) => () => {
    const { dataTable } = this.state;
    let fillData = (dataTable || []).filter(val => val?.noRegistrasi === noReg);
    this.setState({ dataPasien: fillData, showModal: true });
  }

  _handleChangeSearch = (e) => {
    e.preventDefault();
    this.setState({ search: e.target.value }, () => {
      this._handleSearchPatient();
    });
  }

  _handleSearchPatient = () => {
    const { search } = this.state;
    let searchPatient = this.state.preliminaryData.filter((val) => {
      return val?.namaLengkap.toLowerCase().includes(search.toLowerCase());
    });
    this.setState({ dataTable: searchPatient });
  }

  _handleChangePagination = (e, page) => {
    const from = (page - 1) * pageSize;
    const to = (page - 1) * pageSize + pageSize;
    this.setState({
      pagination: {
        ...this.state.pagination,
        from, to
      }
    });
  }

  _handleCombine = (idx, noReg) => () => {
    const { dataTable } = this.state;
    let obj = Object.assign({}, dataTable?.filter(val => val?.noRegistrasi === noReg));
    this.setState({ loading: true, index: idx }, () => {
      setTimeout(() => {
        this.setState({ loading: false, index: null });
        let data = { ...obj[0], isCombine: true };
        set(ref(db, 'pasien/' + data.queueNumber), { ...data });
      }, 3500);
    });
  }

  _renderModal = () => {
    const { showModal, dataPasien } = this.state;
    return (
      <Modal open={showModal} onClose={this._handleCloseModal}>
        <Box sx={styleModalForm}>
          <PDFViewer style={viewer}>
            <Document>
              <Page size="A4" style={page}>
                <View style={section}>
                  <CombineForm data={dataPasien} />
                </View>
                {
                  (dataPasien && dataPasien[0] && dataPasien[0]?.isInspectionLab !== undefined) &&
                  <View style={section} break wrap={false}>
                    <LabForm data={dataPasien} />
                  </View>
                }
                {
                  (dataPasien && dataPasien[0] && dataPasien[0]?.isInspectionRadiologi !== undefined) &&
                  <View style={section} break wrap={false}>
                    <RadiologiForm data={dataPasien} />
                  </View>
                }
                {
                  (dataPasien && dataPasien[0] && dataPasien[0]?.billingKassa?.obatObatan !== undefined) &&
                  <View style={section} break wrap={false}>
                    <FarmasiForm data={dataPasien} />
                  </View>
                }
                <View style={section} break wrap={false}>
                  <KassaForm data={dataPasien} />
                </View>
              </Page>
            </Document>
          </PDFViewer>
        </Box>
      </Modal>
    )
  }

  render() {
    const { dataTable, search, pagination, loading, index } = this.state;
    const newData = dataTable.slice(pagination?.from, pagination?.to);
    return (
      <div>
        {this._renderModal()}
        <FormControl sx={{ marginBottom: 1, marginTop: -1 }}>
          <OutlinedInput
            endAdornment={
              <InputAdornment>
                <Search />
              </InputAdornment>
            }
            onChange={this._handleChangeSearch}
            placeholder="Cari pasien..."
            type="text"
            value={search}
            variant="standart"
            size='small'
            color="primary"
          />
        </FormControl>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>NO</StyledTableCell>
                <StyledTableCell>NAMA PASIEN</StyledTableCell>
                <StyledTableCell>NO RM</StyledTableCell>
                <StyledTableCell>ASURANSI</StyledTableCell>
                <StyledTableCell>TUJUAN POLI</StyledTableCell>
                <StyledTableCell>TGL BERKUNJUNG</StyledTableCell>
                <StyledTableCell align='center'>AKSI</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.length === 0 ?
                <StyledTableRow>
                  <StyledTableCell colSpan={5}>Tidak Ada Pasien</StyledTableCell>
                </StyledTableRow> :
                (newData || []).map((val, idx) => {
                  let renderAction = '';
                  if (val?.isCombine && loading === false) {
                    renderAction = <CheckCircle sx={{ color: '#02a345' }} />
                  } else {
                    if (loading && index === idx && val?.isCombine === undefined) {
                      renderAction = <CircularProgress size={30} />
                    } else {
                      if (val?.isCombine === undefined) {
                        renderAction = <Button
                        sx={{ textTransform: 'capitalize' }}
                        size="small"
                        variant="contained"
                        onClick={this._handleCombine(idx, val?.noRegistrasi)}
                      >
                        Combine File
                      </Button>
                      } else {
                        renderAction = <CheckCircle sx={{ color: '#02a345' }} />
                      }
                    }
                  }

                  return (
                    <StyledTableRow key={idx}>
                      <StyledTableCell component="th" scope="row">{idx + 1}</StyledTableCell>
                      <StyledTableCell>{val?.namaLengkap}</StyledTableCell>
                      <StyledTableCell>{val?.noRM}</StyledTableCell>
                      <StyledTableCell>{val?.asuransi}</StyledTableCell>
                      <StyledTableCell>{val?.poli}</StyledTableCell>
                      <StyledTableCell>{moment(val?.tglBerkunjung).format('DD/MM/YYYY')}</StyledTableCell>
                      <StyledTableCell align="center">{renderAction}</StyledTableCell>
                      <StyledTableCell>
                        {val?.isCombine &&
                          <IconButton onClick={this._handlePrint(val?.noRegistrasi)}><Print /></IconButton>
                        }
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {
          dataTable.length !== 0 &&
          <Box
            justifyContent={"flex-end"}
            alignItems={"center"}
            display={"flex"}
            sx={{
              marginTop: 2
            }}
          >
            <Pagination
              color='primary'
              count={Math.ceil(pagination?.count / pageSize)}
              onChange={this._handleChangePagination}
            />
          </Box>
        }
      </div>
    )
  }
};

export default CombineFile;