import { Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import React, { Component } from 'react';

const styles = StyleSheet.create({
  table: { width: '100%', marginTop: '20px' },
  table2: { width: '100%', marginTop: '2px' },
  row: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #000',
    paddingTop: 8,
    paddingBottom: 8,
  },
  header: { border: '1px solid #000' },
  bold: { fontWeight: 'bold' },
  row1: { width: '20%', borderRight: '1px solid #000' },
  row2: { width: '20%', borderRight: '1px solid #000' },
  row3: { width: '20%', borderRight: '1px solid #000' },
  row4: { width: '20%', borderRight: '1px solid #000' },
  row5: { width: '20%' },
  fontBold: { fontWeight: 'bold' },
  tandaVital: {
    row1: { width: '25%', borderRight: '1px solid #000', textAlign: 'left', marginLeft: '5px' },
    row2: { width: '25%', borderRight: '1px solid #000', textAlign: 'left', marginLeft: '5px' },
    row3: { width: '25%', borderRight: '1px solid #000', textAlign: 'left', marginLeft: '5px' },
    row4: { width: '25%', textAlign: 'left', marginLeft: '5px' },
  },
  penunjang: {
    row1: { width: '33.2%', borderRight: '1px solid #000', textAlign: 'left', marginLeft: '5px' },
    row2: { width: '33.2%', borderRight: '1px solid #000', textAlign: 'left', marginLeft: '5px' },
    row3: { width: '33.2%', textAlign: 'left', marginLeft: '5px' },
  }
})

class CombineForm extends Component {
  render() {
    const { data } = this.props;
    return (
      <div>
        <div>
          <div style={{ borderBottom: '1px solid black', textAlign: 'right', padding: 5 }}>
            <Text style={{ fontSize: 18 }}>Hermina Arcamanik Hospital (RSU Hermina Arcamanik)</Text>
            <Text>Jl. A.H. Nasution No.50, Antapani Wetan, Kec. Antapani</Text>
            <Text>Kota Bandung, Jawa Barat 40291</Text>
            <Text>Phone: 022-87242525. Fax: 022-7271771</Text>
          </div>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Text>RESUME PASIEN RAWAT JALAN</Text>
            <Text>POLIKLINIK {(data && data[0] && data[0]?.poli).toUpperCase()}</Text>

            <View style={styles.table}>
              <View style={[styles.row, styles.bold, styles.header]}>
                <Text style={styles.row1}>KODE REGISTRASI</Text>
                <Text style={styles.row2}>NO. REKAM MEDIS</Text>
                <Text style={styles.row3}>NAMA LENGKAP</Text>
                <Text style={styles.row4}>ALAMAT</Text>
                <Text style={styles.row5}>TANGGAL BERKUNJUNG</Text>
              </View>
              {data.map((row, i) => (
                <View key={i} style={styles.row} wrap={false}>
                  <Text style={styles.row1}>{row.noRegistrasi}</Text>
                  <Text style={styles.row2}>{row.noRM}</Text>
                  <Text style={styles.row3}>{row.namaLengkap}</Text>
                  <Text style={styles.row4}>{row.alamat}</Text>
                  <Text style={styles.row5}>{moment(data && data[0] && data[0]?.tglBerkunjung).format('DD/MM/YYYY')}</Text>
                </View>
              ))}
            </View>

            <Text style={{ textAlign: "left", marginTop: '20px', marginBottom: '-10px' }}>TANDA VITAL</Text>
            <View style={styles.table}>
              <View style={[styles.row, styles.bold, styles.header]}>
                <Text style={styles.tandaVital.row1}>Tekanan Darah : {data && data[0] && data[0]?.dataResume?.tensi}</Text>
                <Text style={styles.tandaVital.row2}>Nadi : {data && data[0] && data[0]?.dataResume?.nadi}</Text>
                <Text style={styles.tandaVital.row3}>Respirasi : {data && data[0] && data[0]?.dataResume?.respirasi}</Text>
                <Text style={styles.tandaVital.row4}>Suhu : {data && data[0] && data[0]?.dataResume?.suhu}</Text>
              </View>
            </View>

            <View style={styles.table}>
              <View style={[styles.row, styles.bold, styles.header]}>
                <Text style={styles.tandaVital.row1}>ANAMNESIS : {data && data[0] && data[0]?.dataResume?.anamnesis}</Text>
                <Text style={styles.tandaVital.row2}>PEMERIKSAAN FISIK : {data && data[0] && data[0]?.dataResume?.pemeriksaanFisik}</Text>
                <Text style={styles.tandaVital.row3}>HASIL PENUNJANG : {data && data[0] && data[0]?.dataResume?.hasilPenunjang}</Text>
                <Text style={styles.tandaVital.row4}>DIAGNOSIS : {data && data[0] && data[0]?.dataResume?.diagnosis}</Text>
              </View>
            </View>
            <View style={styles.table2}>
              <View style={[styles.row, styles.bold, styles.header]}>
                <Text style={styles.tandaVital.row1}>TERAPI/TINDAKAN : {data && data[0] && data[0]?.dataResume?.terapi}</Text>
                <Text style={styles.tandaVital.row2}>JENIS PENYAKIT : {data && data[0] && data[0]?.dataResume?.jenisPenyakit}</Text>
                <Text style={styles.tandaVital.row3}>BILA KRONIS, TERMASUK DIAGNOSIS PRB? : {data && data[0] && data[0]?.dataResume?.PRB === false ? '' : data && data[0] && data[0]?.dataResume?.PRB}</Text>
                <Text style={styles.tandaVital.row4}>ALASAN TIDAK DILAKUKAN PRB : {data && data[0] && data[0]?.dataResume?.alasanTidakPRB}</Text>
              </View>
            </View>

            <View style={styles.table}>
              <View style={[styles.row, styles.bold, styles.header]}>
                <Text style={styles.penunjang.row1}>PRE-OPERASI : {data && data[0] && data[0]?.dataResume?.operasi ? 'YA' : 'TIDAK'}</Text>
                <Text style={styles.penunjang.row2}>KONSUL LAB : {data && data[0] && data[0]?.dataResume?.needLab ? 'YA' : 'TIDAK'}</Text>
                <Text style={styles.penunjang.row3}>KONSUL RADIOLOGI : {data && data[0] && data[0]?.dataResume?.needRadiologi ? 'YA' : 'TIDAK'}</Text>
              </View>
            </View>

            <Text style={{ textAlign: "right", marginTop: '30px', marginBottom: '30px' }}>DOKTER PENANGGUNG JAWAB</Text>
            <Text style={{ textAlign: "right" }}>{(data && data[0] && data[0]?.dokterPoli)?.toUpperCase()}</Text>
          </div>
        </div>
        {/* <div>
          <View break wrap={false}>
            <Text>
              AAAAAAAAAAAAAA
            </Text>
          </View>
        </div> */}
      </div>
    );
  }
}

export default CombineForm;