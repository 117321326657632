import { Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import React, { Component } from 'react';

const styles = StyleSheet.create({
  table: { width: '100%', marginTop: '20px' },
  table2: { width: '100%', marginTop: '2px' },
  row: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #000',
    paddingTop: 8,
    paddingBottom: 8,
  },
  header: { border: '1px solid #000', backgroundColor: 'tomato' },
  headerRadiologi: { border: '1px solid #000', backgroundColor: 'cornflowerblue' },
  headerTitle: { border: '1px solid #000', backgroundColor: 'grey' },
  bold: { fontWeight: 'bold' },
  row1: { width: '20%', borderRight: '1px solid #000' },
  row2: { width: '20%', borderRight: '1px solid #000' },
  row3: { width: '20%', borderRight: '1px solid #000' },
  row4: { width: '20%', borderRight: '1px solid #000' },
  row5: { width: '20%' },
  fontBold: { fontWeight: 'bold' },
  tandaVital: {
    row1: { width: '25%', borderRight: '1px solid #000', textAlign: 'left', marginLeft: '5px' },
    row2: { width: '25%', borderRight: '1px solid #000', textAlign: 'left', marginLeft: '5px' },
    row3: { width: '25%', borderRight: '1px solid #000', textAlign: 'left', marginLeft: '5px' },
    row4: { width: '25%', textAlign: 'left', marginLeft: '5px' },
  },
  penunjang: {
    row1: { width: '33.2%', borderRight: '1px solid #000', textAlign: 'left', marginLeft: '5px' },
    row2: { width: '33.2%', borderRight: '1px solid #000', textAlign: 'left', marginLeft: '5px' },
    row3: { width: '33.2%', textAlign: 'left', marginLeft: '5px' },
  },
  title: {
    row: { width: '100%', textAlign: 'left', marginLeft: '5px' }
  }
})

class RadiologiForm extends Component {
  render() {
    const { data } = this.props;
      return (
        <div>
          <div style={{ borderBottom: '1px solid black', textAlign: 'right', padding: 5 }}>
            <Text style={{ fontSize: 18 }}>Hermina Arcamanik Hospital (RSU Hermina Arcamanik)</Text>
            <Text>Jl. A.H. Nasution No.50, Antapani Wetan, Kec. Antapani</Text>
            <Text>Kota Bandung, Jawa Barat 40291</Text>
            <Text>Phone: 022-87242525. Fax: 022-7271771</Text>
          </div>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Text>HASIL RADIOLOGI</Text>

            <View style={styles.table}>
              <View style={[styles.row, styles.bold, styles.headerRadiologi]}>
                <Text style={styles.tandaVital.row1}>NO. RM</Text>
                <Text style={styles.tandaVital.row2}>NAMA</Text>
                <Text style={styles.tandaVital.row3}>TGL LAHIR</Text>
                <Text style={styles.tandaVital.row4}>DOKTER PENGIRIM</Text>
              </View>
              {data.map((row, i) => (
                <View key={i} style={styles.row} wrap={false}>
                  <Text style={styles.tandaVital.row1}>{row.noRM}</Text>
                  <Text style={styles.tandaVital.row2}>{row.namaLengkap}</Text>
                  <Text style={styles.tandaVital.row3}>{moment(row?.tglLahir).format('DD/MM/YYYY')}</Text>
                  <Text style={styles.tandaVital.row4}>{row.dokterPoli}</Text>
                </View>
              ))}
            </View>

            <View style={styles.table}>
              <View style={styles.row} wrap={false}>
                <Text style={styles.title.row}>{data && data[0] && data[0]?.hasilRadiologi?.keterangan}</Text>
              </View>
            </View>

            <Text style={{ textAlign: "right", marginTop: '30px', marginBottom: '30px' }}>PEMERIKSA</Text>
            <Text style={{ textAlign: "right" }}>{(data && data[0] && data[0]?.hasilRadiologi?.pemeriksa) || ''}</Text>
          </div>
        </div>
      );
    }
}

export default RadiologiForm;