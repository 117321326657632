import { initializeApp } from "firebase/app";
import { getDatabase } from 'firebase/database';

export default function StartFirebaseUser() {
  const firebaseConfigUser = {
    apiKey: "AIzaSyAFXmyOwEB4PL7ORL20hyD5V7JSXcyirig",
    authDomain: "user-management-69a4d.firebaseapp.com",
    projectId: "user-management-69a4d",
    storageBucket: "user-management-69a4d.appspot.com",
    messagingSenderId: "13077293535",
    appId: "1:13077293535:web:ace3bb600752bc9e36bc6a"
  };

  const app = initializeApp(firebaseConfigUser, "secondary");
  return getDatabase(app);
}
