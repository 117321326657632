import './App.css';
import React, { Component } from 'react';
import ListPasien from './pages/ListPasien';
import RegistrasiPasien from './pages/RegistrasiPasien';
import Login from './pages/Login';
import Sidebar from './components/sidebar';
import CombineFile from './pages/CombineFile';

class App extends Component {
  state = {
    valueTab: 0,
    valLogin: localStorage.getItem('login'),
    unit: localStorage.getItem('unit'),
  }

  _handleActiveMenu = (val) => {
    this.setState({ valueTab: val });
  }

  render() {
    const { valueTab, valLogin, unit } = this.state;
    let rendering;
    if (valueTab === 0) {
      rendering = <ListPasien />;
    } else if (valueTab === 1) {
      rendering = <RegistrasiPasien />
    } else {
      rendering = <CombineFile/>
    }


    if (valLogin && unit === 'rekamMedis') {
      return (
        <div style={{ height: '100vh', overflow: 'hidden' }}>
          <div style={{ display: 'flex', width: '100%', height: '100vh' }}>
            <Sidebar
              menuActive={this._handleActiveMenu}
            />
            <div style={{
              width: '80%',
              padding: '30px',
              overflow: 'auto'
            }}>
              {rendering}
            </div>
          </div>
        </div>
      );
    } else {
      return <Login />;
    }
  }
}

export default App;