import React, { Component } from 'react';
import './styles.css';
import { DirectionsWalkOutlined, FormatListBulleted, JoinFull, Logout } from '@mui/icons-material';

let list = [
  {
    icon: <FormatListBulleted style={{ marginRight: '5px' }} />,
    title: 'List Pasien Rawat Jalan'
  },
  {
    icon: <DirectionsWalkOutlined style={{ marginRight: '5px' }} />,
    title: 'Registrasi Ulang Pasien Rawat Jalan'
  },
  {
    icon: <JoinFull style={{ marginRight: '5px' }} />,
    title: 'Combine Dokumen Pasien'
  },
]

class Sidebar extends Component {
  state = {
    menu: 0
  }

  _handleSubMenuActive = (idx) => (e) => {
    const { menuActive } = this.props;
    e.preventDefault();
    if (idx === this.state.menu) {
      this.setState({ menu: idx });
    } else {
      this.setState({ menu: idx });
    }
    menuActive(idx);
  };

  _handleLogout = () => {
    localStorage.removeItem('login');
    localStorage.removeItem('unit');
    localStorage.removeItem('username');
    window.location.reload();
  }

  render() {
    const { menu } = this.state;
    return (
      <div className='containerSidebar'>
        <h1 style={{
          textAlign: 'center',
          borderBottom: '1px solid black',
          padding: '40px 10px'
        }}>
          RAWAT JALAN
        </h1>
        {list.map((val, idx) => {
          return (
            <div
              className={menu === idx ? 'title-active' : 'title'}
              onClick={this._handleSubMenuActive(idx)}
            >
              {val.icon} {val.title}
            </div>
          );
        })}
        <div className='title-logout' onClick={this._handleLogout}>
          <Logout style={{ marginRight: '5px' }} /> Logout
        </div>
      </div>
    );
  }
}

export default Sidebar;